<template>
    <modal ref="modalSeleccionarAvatar" titulo="Selecciona un Avatar" tamano="modal-lg" :no-aceptar="true" :no-cancelar="true" :btns="buttons" @accion="acciones">
        <div class="row mx-0" style="height:calc(50vh - 35px);">
            <div class="col-2" />
            <div class="col">
                <div v-for="(grupo, idx) in grupos" :key="idx">
                    <div class="row mx-0 px-2">
                        <div class="col px-0 border br-10">
                            <div class="d-middle mx-0">
                                <div class="px-2 br-l-10" :class="true?'bg-gr-red':'bg-general2'" style="height:40px;" />
                                <p class="tres-puntos f-17 mx-2">{{ grupo.nombre }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-for="(avatar, a) in grupo.avatares" :key="a" class="row px-0 mx-1 my-2">
                        <div class="rounded-circle p-2 cr-pointer" :class="id_avatar == avatar.id ? 'current-avatar' : 'avatar'" style="width:66px;height:66px;" @click="elegir_avatar(avatar)">
                            <img :src="avatar.imagen_firmada" width="100%" height="100%" class="obj-cover rounded-circle" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2" />
        </div>
    </modal>
</template>

<script>
import Avatar from "~/services/configurar/admin/grupoAvatares"

export default {
    data(){
        return {
            id_avatar: 1,
            avatar_seleccionado: null,
            grupos:[],
            buttons: [
                { texto: 'Guardar Avatar', color: 'bg-leeche px-5',accion: 'avatar' }
            ]
        }
    },
    methods: {
        toggle(id_avatar){
            this.get_avatares()
            this.id_avatar = id_avatar
            this.$refs.modalSeleccionarAvatar.toggle();
        },
        async get_avatares(){
            try {
                const { data } =  await Avatar.grupoAvatares()
                this.grupos = data.data

            } catch (error){
                this.error_catch(error)
            }
        },
        acciones(click){
            if (click == 'avatar'){
                this.$emit('avatar', this.avatar_seleccionado)
                this.$refs.modalSeleccionarAvatar.toggle()
            }
        },
        elegir_avatar(avatar){
            this.id_avatar = avatar.id
            this.avatar_seleccionado = avatar
        }
    }
}
</script>

<style style="scss" scoped>
.current-avatar{
    background-color:#F5F5F5 !important;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DBDBDB ;
}
.avatar{
    background-color: #fff;
}
</style>
